/* --------------------- Premium counslting partners---------- */
@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 3));
    }
  }
  .slider {
    width: 80%;
    background: white;
    height: 140px;
    margin: auto;
    overflow: hidden;
    position: relative;
    /* border: 1px solid #fc9208; */
  }
  .slider-wrap {
    animation: scroll 15s linear infinite;
    display: flex;
    width: calc(250px * 6);
    height: 100%;
    padding: 10px;
  }
  .slide-image {
    /* width: 90%;
    height: 100%; */
    object-fit: contain;
    height: 100px;
    width: 200px;
  }
  .partner-slide {
    width: 250px;
    height: 100%;
    text-align: center;
  }
  .partner-heading {
    text-align: center;
    margin-top: 50px;
    color: #425066;
  }
  .partner-slider-col {
    margin-top: 20px;
  }