.modal-container{
    background-image: 
    linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
    width: 600px;
}
.modal-heading{
    color: white;
}
.services-quote-btn{
    margin-top: 20px;
    background-color:#F2903D ;
   padding: 5px 10px;
    width: 200px;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 1.7rem;
    transition: .5s ease-in-out ;
}
.services-quote-btn:hover{
    transform: scale(1.1);
    background-color:  #5AC247;
}

.account-management-btn{
    position: absolute;
}