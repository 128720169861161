.whatsapp-action-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatsapp-action-button-container a{
    background-color: green;
    color: white;
    text-decoration: none;
    padding: 20px 40px;
    font-size: 50px;
    font-weight: bold;
    border-radius:50px ;
}

.whatsapp-action-button-container a:hover{
    background-color: gold;
}