/*-----styling of services header start from here---------- */
.services-header{
    margin-top: 50px;
}
.header-img{
    width: 700px;
    height: 300px;
}
.services-content{
    margin-top: 50px;
    padding-left: 60px;
}
.service-name{
    color:#F2903D;
}


/*-----styling of services header end  here---------- */


/* --------styling of article start from here ------------ */

.article-container{
    margin-top: 60px;
}
.article{
    font-size: 18px;
    line-height: 35px;
    font-family: sans-serif;
    color: #666;
    padding: 0 100px;
}
.article-catalogue-service-img{
    width: 800px;
    height: 400px;
    border-radius: 20px;
}
@media (max-width:720px) {
    .article{
        padding: 0 10px;
    }
    .header-img{
        width: 95vw;
    }
}

/* --------styling of article end from here ------------ */


/* --------styling of Account Management services  start from here ------------ */

.Account-Management-header{
    margin-top: 50px;
    position: relative;
    text-align: center;

}
.account-management-header-img{
    width: 100%;
    height: 400px;
    border-radius: 20px;
    margin-left: auto;
    margin-right:auto ;
}
.account-management-btn{
    position: absolute;
}
.account-mg-heading{
    color: #F2903D;
}


/* onboarding styling */
.responsiveImage{
    width: 100%;
    height: auto;
}