@import url('https://fonts.googleapis.com/css?family=Montserrat');



.testimonials-container {
    background-image: 
    linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
	border-radius: 18px;
	color: #FFFFFF;
	font-family: 'Montserrat';
	margin: 20px auto;
	max-width: 868px;
	padding: 50px 80px;
	position: relative;
}

.fa-quote {
	color: rgba(255, 255, 255, 0.3);
	font-size: 28px;
	position: absolute;
	top: 70px;
}

.fa-quote-right {
	left: 40px;
}

.fa-quote-left {
	right: 40px;
}

.testimonial {
	line-height: 28px;
	text-align: justify;
}

.centered-items {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo {
	border-radius: 50%;
	height: 75px;
	width: 75px;
	object-fit: cover;
}

.user-details {
	margin-left: 10px;
}

.username {
	margin: 0;
}

.role {
	font-weight: normal;
	margin: 2px 0;
}

.progress-bar {
	background-color: #fff;
	height: 4px;
	width: 100%;
	animation: grow 10s linear infinite;
	transform-origin: left;
}

@keyframes grow {
	0% {
		transform: scaleX(0);
	}
}

@media (max-width: 768px) {
	.testimonials-container {
		padding: 20px 30px;
	}
	
	.fa-quote {
		display: none;
	}
}




