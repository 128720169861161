
  
  .form-container {
    font-family: "Source Sans Pro", sans-serif;
    text-align: center;
  position: relative;
  margin-top: 40px;
  }
  
  .form-container h1 {
    font-size: 40px;
    font-weight: 200;

  }
  
  .form-input {
    background-color: #fff;
    border: none;
    width: 500px;
    height: 55px;
    border-radius: 15px;
    font-family: "Source Sans Pro", sans-serif;
    padding: 10px 15px 10px 10px;
    margin: 5px 0 15px 10px;
    display: block;
    text-align: left;
    font-size: 14px;
    color: #616161;
   font-size: 2rem;
   font-weight: 310;

  }
  
  .form-input:focus{
    outline: none;
  }
  
  .form-button {
      display: flex;
    appearance: none;
    outline: 0;
    color: #ffffff;
    border: none;
    border-radius: 15px;
    padding: 5px 10px;
    background-color: #FF6F00;
    width: 150px;
    font-size: 30px;
    height: 50px;
   align-items: flex-start;
   margin-left: 20px;
   text-align: center;
   padding-left: 25px;
  transition: ease-in-out .4s;
  }
  .form-button:hover{
    transform: scale(1.03);
  }
  @media (max-width:420px){
    .form-input{
      width: 330px;
    }
    
  }
  
  