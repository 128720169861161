@import url('https://fonts.googleapis.com/css2?family=PT+Serif+Caption&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  
  /* ---------------styling of divider------------------ */
  
    hr.solid {
      border-top: 2px solid #bab1b1;
      width: 80%;
      margin: auto;
    }
  
  /* -------------------------- Upper-Nav css style--------------------*/
  .upper-nav {
    background-image: 
     linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
    height: 50px;
  }
  .email {
    color: white;
    padding: 5px;
    font-size: 1.4rem;
    margin-left: 80px;
    margin-top: 5px;
  }
  .email .icon {
    padding-right: 20px;
   
  }
  
  .social-icon {
    padding-left: 350px;
  
    font-size: 2rem;
    color: white;
    transition: ease-in-out 1s;
  }
  .social-icon .icon:hover {
    color: white;
    transform: scale(1.2);
  }
  .social-icon .icon {
    padding: 0 20px;
  }
  .whiteColorIcon{
    color: white;
  }
  .mobile-icon{
    margin-left: 5px;
  }
  @media screen and (max-width: 700px) {
    .social-icon {
      padding-left: 20px;
      font-size: 18px;
      margin-top: 8px;
    }
    .social-icon .icon {
      padding: 0 10px;
    }
    .email {
      margin-left: 5px;
      font-size: 15px;
    }
  }
  
  /* --------------------------Navbar css style--------------------*/
  .navbar {
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
  }
  .nav-container-custom-style{
    margin-top: -20px;
  }
  .technovita-logo{
   width:80px;
   height:60px;
  }
  .logo-text{
  font-family: 'PT Serif Caption', serif;
    font-size: 18px;
    color: #ff9002;
    margin-top: -10px;
    padding: 0;
    margin-bottom: -10px;
    
  }
  .logo-text-second{
   padding: 0;
   margin-top: -10px;
   margin-bottom: -10px;
   font-family: 'PT Serif Caption', serif;
   font-size: 16px;
   color: #ff9002;
   padding-left: 7px;
   
  }
  .navbar-brand {
    font-size: 3.5rem;
  }
  .navbar li {
    padding: 0 1.5rem;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #425066;
    font-size: 1.5rem;
  }
  .navbar-light .navbar-nav .nav-link:hover{
    color:#F4A540
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
    background-image: 
     linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
  }
  /* submenu */
    .dropdown-menu li {
      position: relative;
    }
    .dropdown-menu .dropdown-submenu {
      display: none;
      position: absolute;
      left: 100%;
      top: -7px;
    }
    .dropdown-submenu{
      background-image: 
     linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
      border-radius: 4px;
     margin-left: auto;
     margin-right: auto;
    }
    .dropdown-submenu li{
    list-style-type: none;
    
    }
    
    .dropdown-menu > li:hover > .dropdown-submenu {
      display: block;
      
    }
  .payu-btn{
    background-color: #F4A540;
    color: white;
  }

    /* who we are and our mission section which is below slider */
.who-we-are-container{
    margin-top: 80px;
    margin-bottom: 30px;
   
  }
  .who-we-are-top{
padding-left: 20px;
  }
  
  .vl{
    border-left: 5px solid orange;
    height: 100px;
    border-radius: 5px;
    
  }
  .vl h1{
   
    padding-top: 10px;
    padding-left: 10px;
    color: orange;
    font-family:  Helvetica, sans-serif;
  }
  .our-section-text {
   margin-top: 20px;
   padding: 0 20px;
   font-size: 18px;
   color:#425066;
  }
  
  
  /* Features Section */
  .what-we-do {
    text-align: center;
    margin-top: 50px;
    color: #425066;
  }
  .steps-headline {
    margin-top: 70px;
    text-align: center;
    color: #425066;
  }
  #features {
    color: #425066;
    position: relative;
    text-align: center;
  }
  
  .feature-title {
    font-size: 1.5rem;
    color: #425066;
  }
  
  .feature-box {
    padding: 4.5%;
  }
  
  .img {
    width: 150px;
    height: 150px;
    margin-bottom: 1rem;
  }
  .img:hover {
    color: #ff4c68;
  }
  .counter{
    color: #425066;
  }
  .counter-container{
    margin-top: 20px;
    margin-bottom: 50px;
  }
  
  /* ----------------services Section styling ---------------*/
  .services{
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .services-headline{
  margin-bottom: 30px;
  color: #425066;
  }
  
  
  /* counter */
  .counter {
    margin-top: 50px;
    text-align: center;
    padding: 0;
    font-weight: bold;
  }
  
  
  
  /* ---------------call button css------------ */
  @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
  
  
  .call-btn {
      position: fixed;
      margin: 0;
      padding: 0;
      left: 0;
      bottom: 0px;
      background: #fff;
      background-color: transparent;
      cursor: pointer;
      font-size: 0;
      width: 110px;
      height: 110px;
      z-index: 1000;
  }
  
  .call-btn .tada {
      background: #ff9002;
      border-radius: 100px;
      width: 40px;
      height: 40px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -20px;
      margin-left: -20px;
      animation-name: tada;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
  }
  
  .call-btn .tada a:before {
      font-family: FontAwesome;
      content: "\f095";
      font-size: 25px;
      
      text-decoration: none;
      color: #fff;
      margin-left: 10px;
      position: absolute;
      top: 40%;
      margin-top: -10px;
  }
  
  
  @keyframes tada {
      from {
          transform: rotate(-20deg);
      }
      to {
          transform: rotate(20deg);
      }
  }
  
  .call-btn .pulse {
      width: 60px;
      height: 60px;
      background: rgba(255, 144, 2, 0.39);
      ;
      border-radius: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: -30px;
      animation-name: pulse;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
  }
  
  @keyframes pulse {
      from {
          width: 55px;
          height: 55px;
          margin-top: -27.5px;
          margin-left: -27.5px;
      }
      to {
          width: 60px;
          height: 60px;
          margin-top: -30px;
          margin-left: -30px;
      }
  }
  
  .call-btn .zoomIn {
      width: 80px;
      height: 80px;
      border: 2px solid #ff9002;
      border-radius: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -40px;
      margin-left: -40px;
      animation-name: zoomIn;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
  }
  
  @keyframes zoomIn {
      from {
          width: 40px;
          height: 40px;
          margin-top: -20px;
          margin-left: -20px;
      }
      to {
          width: 80px;
          height: 80px;
          margin-top: -40px;
          margin-left: -40px;
      }
  }
  
  @media screen and (max-width: 549px) {
      .call-btn {
          width: 80px;
          height: 80px;
      }
  }
  
  
  /* --------------- footer styling------------ */
  .footer-container{
    background-color: #2066a0;
    color: white;
    padding-left: 120px;
    padding-right: 120px;
    margin-top: 20px;
  }
  .footer-text-container  ul li a{
    color: white;
    list-style-type: none;
   margin-top: 20px;
  }
  
  /* --------------- contact styling------------ */
  .contact-container{
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }
  .contact-container h1{
  color: #425066;
  margin-bottom: 50px;
  }
  .contact-container-info{
 background-image: 
     linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
  height: 300px;
  border-radius: 50px;
  margin-left: 120px;
  width: 400px;
  margin-bottom: 20px;
  
  }
  .contact-container-info h1{
   color: #fff;
   margin-top: 10px;
   margin-bottom: 0;
   text-align: center;
    }
  .contact-container-form{
    background-image: 
    linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
    height: 300px;
    border-radius: 50px;
    width: 600px;
    margin-left: 50px;
  
    }
  
  .call{
    font-size: 1.4rem;
    color: #fff;
    text-align: left;
    margin: 20px 0;
    
  }
  .call-icon{
   background-color: #FF6F00;
   height: 55px;
   width: 55px;
   border-radius: 50px;
  padding: 10px;
  color: #fff;
  margin-right: 10px;
  text-align: center;
  }
  .email-address{
    font-size: 1.4rem;
    color: #fff;
    text-align: left;
    margin: 20px 0;
  }
  .address{
    font-size: 1.3rem;
    color: #fff;
    text-align: left;
    margin: 20px 0;
  }
  @media (max-width:720px) {
    .contact-container-form{
      margin-left: 0;
      width: auto;
    }
    .contact-container-info{
      margin-left: 0;
      width: auto;
      margin-bottom: 30px;
    }
   
  }
    