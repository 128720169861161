.contact-page-sec{
   
    margin: 50px 80px 20px 270px;
}
@media (max-width:700px) {
    .contact-page-sec{
        margin:0;
    }
}


.about-container {
    max-width: 900px;
    margin: 0 auto;
    font-family: "Roboto", sans-serif;
    color: #425066;
  }
  .aboutus-title {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 32px;
    padding: 0 0 11px;
    position: relative;
    text-transform: uppercase;
    color: #000;
    margin-left: 20px;
  }
  .aboutus-title::after {
    background: #fdb801 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    width: 20%;
  }
  
  .about-container p {
    line-height: 2;
    padding: 0 20px;
  }
  
  .about-container .profile-image {
    text-align: center;
    padding: 30px;
  }
  
  .about-container .profile-image img {
    width: 200px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    padding: 8px;
  }
  
  
  
  
  
  .our-team-heading {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 32px;
    padding: 0 0 11px;
    position: relative;
    text-transform: uppercase;
    color: #000;
    margin-left: 20px;
  }
  .our-team-heading::after{
    background: #fdb801 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    width: 20%;
  }
  
  .our-team {
    display: flex;
    padding: 30px;
  }
  
  .our-team img {
    width: 100%;
  }
  
  .our-team .team-member {
    margin: 0 4px;
    position: relative;
  }
  
  .our-team .designation {
    position: absolute;
    bottom: 30px;
    background: #fff;
    padding: 12px 20px;
    border-radius: 0 20px 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    font-size: 14px;
  }
  
  .about-container .our-mission {
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0 20px;

  }
  
  .about-container .our-mission .quote-icon {
    position: absolute;
    opacity: 0.2;
    height: 80px;
  }
  
  .about-container .our-mission p {
    margin-left: 100px;
    font-size: 20px;
    font-style: italic;
  }
  .about-description{
font-size: 20px;
  }
  @media (max-width: 700px) {
    .our-team {
      flex-direction: column;
      align-items: center;
    }
  
    .our-team img {
      height: 250px;
      width: 300px;
      object-fit: cover;
    }
  }
  
