 .card-img-top{
     height: 100px;
     width: 100%;

 }
.card{
   z-index: 1;
   overflow: hidden;
   border-radius: 20px;
   cursor: pointer;
   text-decoration: none;
   height: 420px;
   width: 270px;
   margin-top: 20px;

}
.card-title{
    
    color: #425066;
    text-align: left;
    font-size: 25px;
  }

  .card-body-text{
    color: #425066;
    text-align: left;
    font-size: 18px;
  }
  .card-btn{
    text-decoration: none;
    padding-top: 10px;
    color: #425066;
    font-size: 1rem;
    font-weight:bolder;
    text-align: left;
  }

.card:hover{
    transform:scale(1.05);
    transition: all .5s ease-in-out;

}
.card:hover .card-title{
    color: white;
}
.card:hover .card-body-text{
    color: white;
}
 .card::before{
     content: '';
     position: absolute;
     left: -100%;
     top: 0%;
     background-image: 
     linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
     width: 100%;
     height: 100%;
    transition: 0.5s;
    z-index: -1;
 }
 .card:hover::before{
     left: 0%;
 }
 .col-12{
   flex: 0 0;
 }
















 /* -----------styling for card component--------------- */

/* .card{
    background-color: white;
    width: 270px;
    height: 400px;
    margin: 10px;
    border-radius: 20px;
    transition: all .5s ease-in-out;
  }
  .card-image{
    height: 150px;
    margin-bottom: 15px;
    border-radius: 20px 20px 0 0;
    border-bottom: rgb(144, 142, 142) .5px solid;
    background-image: url("https://www.digicommerce.in/images/catalogue3.webp");
  background-size: contain;
  background-repeat: no-repeat;
  
  }
 
  .card-text{
    padding: 0 10px 10px 10px;
    color: #425066;
    text-align: left;
  }
 
  .card:hover {
    background-color:#f2903d ;
    box-shadow: 5px 5px 19px 2px #DADBE8, 
  -5px -5px 12px 0px #DADBE8;
  transform:scale(1.05);
  } */