/******* google font ****/
@import url('https://fonts.googleapis.com/css?family=Poppins|Ubuntu&display=swap');
.other-services
{
    display: block;
    position: relative;
    padding: 0;
    margin-bottom: 50px;
}
.other-services-container
{
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}
.other-services-title
{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}


.other-services-box
{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: -15px;
}

.other-service-title
{
    margin-left: 15px;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom:40px;
    text-align: center;
    top: 26px;

}
.other-services-title h2 span
{
    color:#fc6a0e;
}
.other-services-title h2
{
    margin-top: -10px;
    margin-bottom: 6px;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: #333;
    font-family:'Ubuntu',sans-serif;
}

.other-box
{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 33.333333%;
}
.ser-box
{
    margin-top: 75px;
    padding-bottom: 30px;
    padding: 0 60px;
    min-height: 198px;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
    transition: all .4s ease-in-out;
    box-shadow: 0 0 25px 0 rgba(20,27,202,.17);
}

.ser-box .icon
{
    margin-bottom: 0;
    transform: translateY(-50%);
    text-align: center;
}
.ser-box h4
{
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    color: #3c44a9;
    margin-top: -10px;
    font-family: 'Ubuntu',sans-serif;
}
.ser-box p
{
    color: #61648e;
    line-height: 24px;
    margin-top: 20px;
    font-family:'Ubuntu',sans-serif;
}
.ser-box:hover
{
    box-shadow: 0 0 25px 0 rgba(20,27,201,.05);
    cursor: pointer;
    background-image: 
    linear-gradient(-45deg, #fb0054 0%, #f55b2a 100%);
}

.ser-box:hover h4,
.ser-box:hover p
{
    color: #fff;
}



.other-services-box .other-box:nth-child(1) .ser-box:hover
{
    background-image: 
    linear-gradient(-45deg, #34b5bf 0%, #210c59 100%);
}



.other-services-box .other-box:nth-child(3) .ser-box:hover
{
    background-image: 
    linear-gradient(-45deg, #3615e7 0%, #44a2f6 100%);
}

.other-services-box .other-box:nth-child(4) .ser-box:hover
{
    background-image: 
    linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
}


.other-services-box .other-box:nth-child(5) .ser-box:hover
{
    background-image: 
    linear-gradient(-45deg, #8d40fb 0%, #5a57fb 100%);
}



.other-services-box .other-box:nth-child(6) .ser-box:hover
{
    background-image: 
    linear-gradient(-45deg, #27b88d 0%, #22dd73 100%);
}



/********-Responsive-************/

@media(min-width: 768px)
{
    .other-box
    {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
}





@media(min-width: 600px)
{
    .other-box
    {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
}



@media(min-width: 992px)
{
    .other-box
    {
        flex: 0 0 33.333333% !important;
        max-width: 33.333333% !important;
    }
}



@media(min-width: 414px)
{
    .other-box
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
}


@media(min-width: 370px)
{
    .other-box
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
}


@media(min-width: 320px)
{
    .other-box
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
}





















